import Picker from '../picker'
import TimePanel from '../panel/time'
import TimeRangePanel from '../panel/time-range'

export default {
  mixins: [Picker],

  name: 'MetaTimePicker',

  props: {
    isRange: Boolean,
    arrowControl: Boolean
  },

  data () {
    return {
      type: ''
    }
  },

  watch: {
    isRange (isRange) {
      if (this.picker) {
        this.unmountPicker()
        this.type = isRange ? 'timerange' : 'time'
        this.panel = isRange ? TimeRangePanel : TimePanel
        this.mountPicker()
      } else {
        this.type = isRange ? 'timerange' : 'time'
        this.panel = isRange ? TimeRangePanel : TimePanel
      }
    }
  },

  created () {
    this.type = this.isRange ? 'timerange' : 'time'
    this.panel = this.isRange ? TimeRangePanel : TimePanel
  }
}
