<template>
  <header class="meta-header" :style="{ height }">
    <slot></slot>
  </header>
</template>

<script>
export default {
  name: 'MetaHeader',

  componentName: 'MetaHeader',

  props: {
    height: {
      type: String,
      default: '60px'
    }
  }
}
</script>
