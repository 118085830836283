<template>
  <li class="meta-timeline-item">
    <div class="meta-timeline-item__tail"></div>
    <div class="meta-timeline-item__box">
      <div v-if="!$slots.dot"
        class="meta-timeline-item__node"
        :class="[
          `meta-timeline-item__node--${size || ''}`,
          `meta-timeline-item__node--${type || ''}`
        ]"
        :style="{
          backgroundColor: color
        }"
      >
        <i v-if="icon"
          class="meta-timeline-item__icon"
          :class="icon"
        ></i>
      </div>
      <div v-if="$slots.dot" class="meta-timeline-item__dot">
        <slot name="dot"></slot>
      </div>
    </div>
    <div class="meta-timeline-item__wrapper">
        <div v-if="!hideTimestamp && placement === 'top'"
          class="meta-timeline-item__timestamp is-top">
          {{timestamp}}
        </div>

        <div class="meta-timeline-item__content">
          <slot></slot>
        </div>

        <div v-if="!hideTimestamp && placement === 'bottom'"
          class="meta-timeline-item__timestamp is-bottom">
          {{timestamp}}
        </div>
      </div>
  </li>
</template>

<script>
export default {
  name: 'MetaTimelineItem',

  inject: ['timeline'],

  props: {
    timestamp: String,

    hideTimestamp: {
      type: Boolean,
      default: false
    },

    placement: {
      type: String,
      default: 'bottom'
    },

    type: String,

    color: String,

    size: {
      type: String,
      default: 'normal'
    },

    icon: String
  }
}
</script>
