<template>
  <transition name="meta-zoom-in-top">
    <div
      class="meta-table-filter"
      v-if="multiple"
      v-clickoutside="handleOutsideClick"
      v-show="showPopper">
      <div class="meta-table-filter__content">
        <meta-scrollbar wrap-class="meta-table-filter__wrap">
          <meta-checkbox-group class="meta-table-filter__checkbox-group" v-model="filteredValue">
            <meta-checkbox
              v-for="filter in filters"
              :key="filter.value"
              :label="filter.value">{{ filter.text }}</meta-checkbox>
          </meta-checkbox-group>
        </meta-scrollbar>
      </div>
      <div class="meta-table-filter__bottom">
        <button @click="handleConfirm"
          :class="{ 'is-disabled': filteredValue.length === 0 }"
          :disabled="filteredValue.length === 0">{{ t('el.table.confirmFilter') }}</button>
        <button @click="handleReset">{{ t('el.table.resetFilter') }}</button>
      </div>
    </div>
    <div
      class="meta-table-filter"
      v-else
      v-clickoutside="handleOutsideClick"
      v-show="showPopper">
      <ul class="meta-table-filter__list">
        <li class="meta-table-filter__list-item"
            :class="{ 'is-active': filterValue === undefined || filterValue === null }"
            @click="handleSelect(null)">{{ t('el.table.clearFilter') }}</li>
        <li class="meta-table-filter__list-item"
            v-for="filter in filters"
            :label="filter.value"
            :key="filter.value"
            :class="{ 'is-active': isActive(filter) }"
            @click="handleSelect(filter.value)" >{{ filter.text }}</li>
      </ul>
    </div>
  </transition>
</template>

<script type="text/babel">
import Popper from '@@/utils/vue-popper'
import { PopupManager } from '@@/utils/popup'
import Locale from '@@/mixins/locale'
import Clickoutside from '@@/utils/clickoutside'
import Dropdown from './dropdown'
import MetaCheckbox from '@@@/checkbox'
import MetaCheckboxGroup from '@@@/checkbox-group'
import MetaScrollbar from '@@@/scrollbar'

export default {
  name: 'MetaTableFilterPanel',

  mixins: [Popper, Locale],

  directives: {
    Clickoutside
  },

  components: {
    MetaCheckbox,
    MetaCheckboxGroup,
    MetaScrollbar
  },

  props: {
    placement: {
      type: String,
      default: 'bottom-end'
    }
  },

  methods: {
    isActive (filter) {
      return filter.value === this.filterValue
    },

    handleOutsideClick () {
      setTimeout(() => {
        this.showPopper = false
      }, 16)
    },

    handleConfirm () {
      this.confirmFilter(this.filteredValue)
      this.handleOutsideClick()
    },

    handleReset () {
      this.filteredValue = []
      this.confirmFilter(this.filteredValue)
      this.handleOutsideClick()
    },

    handleSelect (filterValue) {
      this.filterValue = filterValue

      if ((typeof filterValue !== 'undefined') && (filterValue !== null)) {
        this.confirmFilter(this.filteredValue)
      } else {
        this.confirmFilter([])
      }

      this.handleOutsideClick()
    },

    confirmFilter (filteredValue) {
      this.table.store.commit('filterChange', {
        column: this.column,
        values: filteredValue
      })
      this.table.store.updateAllSelected()
    }
  },

  data () {
    return {
      table: null,
      cell: null,
      column: null
    }
  },

  computed: {
    filters () {
      return this.column && this.column.filters
    },

    filterValue: {
      get () {
        return (this.column.filteredValue || [])[0]
      },
      set (value) {
        if (this.filteredValue) {
          if ((typeof value !== 'undefined') && (value !== null)) {
            this.filteredValue.splice(0, 1, value)
          } else {
            this.filteredValue.splice(0, 1)
          }
        }
      }
    },

    filteredValue: {
      get () {
        if (this.column) {
          return this.column.filteredValue || []
        }
        return []
      },
      set (value) {
        if (this.column) {
          this.column.filteredValue = value
        }
      }
    },

    multiple () {
      if (this.column) {
        return this.column.filterMultiple
      }
      return true
    }
  },

  mounted () {
    this.popperElm = this.$el
    this.referenceElm = this.cell
    this.table.bodyWrapper.addEventListener('scroll', () => {
      this.updatePopper()
    })

    this.$watch('showPopper', (value) => {
      if (this.column) this.column.filterOpened = value
      if (value) {
        Dropdown.open(this)
      } else {
        Dropdown.close(this)
      }
    })
  },
  watch: {
    showPopper (val) {
      if (val === true && parseInt(this.popperJS._popper.style.zIndex, 10) < PopupManager.zIndex) {
        this.popperJS._popper.style.zIndex = PopupManager.nextZIndex()
      }
    }
  }
}
</script>
