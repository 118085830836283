<template>
  <aside class="meta-aside" :style="{ width }">
    <slot></slot>
  </aside>
</template>

<script>
export default {
  name: 'MetaAside',

  componentName: 'MetaAside',

  props: {
    width: {
      type: String,
      default: '300px'
    }
  }
}
</script>
