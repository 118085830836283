<template>
  <li class="meta-menu-item-group">
    <div class="meta-menu-item-group__title" :style="{paddingLeft: levelPadding + 'px'}">
      <template v-if="!$slots.title">{{title}}</template>
      <slot v-else name="title"></slot>
    </div>
    <ul>
      <slot></slot>
    </ul>
  </li>
</template>
<script>
export default {
  name: 'MetaMenuItemGroup',

  componentName: 'MetaMenuItemGroup',

  inject: ['rootMenu'],
  props: {
    title: {
      type: String
    }
  },
  data () {
    return {
      paddingLeft: 20
    }
  },
  computed: {
    levelPadding () {
      let padding = 17
      let parent = this.$parent
      if (this.rootMenu.collapse) return 17
      while (parent && parent.$options.componentName !== 'MetaMenu') {
        if (parent.$options.componentName === 'MetaSubmenu') {
          padding += 17
        }
        parent = parent.$parent
      }
      return padding
    }
  }
}
</script>
