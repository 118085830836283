<template>
  <transition name="meta-zoom-in-top" @after-leave="doDestroy">
    <div
      v-show="showPopper"
      class="meta-autocomplete-suggestion meta-popper"
      :class="{ 'is-loading': !parent.hideLoading && parent.loading }"
      :style="{ width: dropdownWidth }"
      role="region">
      <meta-scrollbar
        tag="ul"
        wrap-class="meta-autocomplete-suggestion__wrap"
        view-class="meta-autocomplete-suggestion__list">
        <li v-if="!parent.hideLoading && parent.loading"><i class="meta-icon-loading"></i></li>
        <slot v-else>
        </slot>
      </meta-scrollbar>
    </div>
  </transition>
</template>
<script>
import Popper from '@@/utils/vue-popper'
import Emitter from '@@/mixins/emitter'
import MetaScrollbar from '../../scrollbar'

export default {
  components: { MetaScrollbar },
  mixins: [Popper, Emitter],

  componentName: 'MetaAutocompleteSuggestions',

  data () {
    return {
      parent: this.$parent,
      dropdownWidth: ''
    }
  },

  props: {
    options: {
      default () {
        return {
          gpuAcceleration: false
        }
      }
    },
    id: String
  },

  methods: {
    select (item) {
      this.dispatch('MetaAutocomplete', 'item-click', item)
    }
  },

  updated () {
    this.$nextTick(_ => {
      this.popperJS && this.updatePopper()
    })
  },

  mounted () {
    this.$parent.popperElm = this.popperElm = this.$el
    this.referenceElm = this.$parent.$refs.input.$refs.input || this.$parent.$refs.input.$refs.textarea
    this.referenceList = this.$el.querySelector('.meta-autocomplete-suggestion__list')
    this.referenceList.setAttribute('role', 'listbox')
    this.referenceList.setAttribute('id', this.id)
  },

  created () {
    this.$on('visible', (val, inputWidth) => {
      this.dropdownWidth = inputWidth + 'px'
      this.showPopper = val
    })
  }
}
</script>
