<template>
  <div class="meta-collapse-item"
    :class="{'is-active': isActive, 'is-disabled': disabled }">
    <div
      role="tab"
      :aria-expanded="isActive"
      :aria-controls="`meta-collapse-content-${id}`"
      :aria-describedby ="`meta-collapse-content-${id}`"
    >
      <div
        class="meta-collapse-item__header"
        @click="handleHeaderClick"
        role="button"
        :id="`meta-collapse-head-${id}`"
        :tabindex="disabled ? undefined : 0"
        @keyup.space.enter.stop="handleEnterClick"
        :class="{
          'focusing': focusing,
          'is-active': isActive
        }"
        @focus="handleFocus"
        @blur="focusing = false"
      >
        <slot name="title">{{title}}</slot>
        <slot name="subtitle">
          <i
            class="meta-collapse-item__arrow meta-icon-arrow-right"
            :class="{'is-active': isActive}">
            </i>
        </slot>
      </div>
    </div>
    <meta-collapse-transition>
      <div
        class="meta-collapse-item__wrap"
        v-show="isActive"
        role="tabpanel"
        :aria-hidden="!isActive"
        :aria-labelledby="`meta-collapse-head-${id}`"
        :id="`meta-collapse-content-${id}`"
      >
        <div class="meta-collapse-item__content">
          <slot></slot>
        </div>
      </div>
    </meta-collapse-transition>
  </div>
</template>
<script>
import Emitter from '@@/mixins/emitter'
import { generateId } from '@@/utils/util'

export default {
  name: 'MetaCollapseItem',

  componentName: 'MetaCollapseItem',

  mixins: [Emitter],

  data () {
    return {
      contentWrapStyle: {
        height: 'auto',
        display: 'block'
      },
      contentHeight: 0,
      focusing: false,
      isClick: false,
      id: generateId()
    }
  },

  inject: ['collapse'],

  props: {
    title: String,
    name: {
      type: [String, Number],
      default () {
        return this._uid
      }
    },
    disabled: Boolean
  },

  computed: {
    isActive () {
      return this.collapse.activeNames.indexOf(this.name) > -1
    }
  },

  methods: {
    handleFocus () {
      setTimeout(() => {
        if (!this.isClick) {
          this.focusing = true
        } else {
          this.isClick = false
        }
      }, 50)
    },
    handleHeaderClick () {
      if (this.disabled) return
      this.dispatch('MetaCollapse', 'item-click', this)
      this.focusing = false
      this.isClick = true
    },
    handleEnterClick () {
      this.dispatch('MetaCollapse', 'item-click', this)
    }
  }
}
</script>
