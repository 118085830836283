<script>
import Emitter from '@@/mixins/emitter'

export default {
  name: 'MetaCheckboxGroup',

  componentName: 'MetaCheckboxGroup',

  mixins: [Emitter],

  inject: {
    metaFormItem: {
      default: ''
    }
  },

  props: {
    value: {},
    disabled: Boolean,
    min: Number,
    max: Number,
    size: String,
    fill: String,
    textColor: String
  },

  computed: {
    _metaFormItemSize () {
      return (this.metaFormItem || {}).elFormItemSize
    },
    checkboxGroupSize () {
      return this.size || this._metaFormItemSize || (this.$METAUI || {}).size
    }
  },

  watch: {
    value (value) {
      this.dispatch('MetaFormItem', 'el.form.change', [value])
    }
  }
}
</script>

<template>
  <div class="meta-checkbox-group" role="group" aria-label="checkbox-group">
    <slot></slot>
  </div>
</template>
