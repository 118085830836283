<template>
  <main class="meta-main">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: 'MetaMain',
  componentName: 'MetaMain'
}
</script>
