<template>
  <span class="meta-breadcrumb__item">
    <span
      :class="['meta-breadcrumb__inner', to ? 'is-link' : '']"
      ref="link"
      role="link">
      <slot></slot>
    </span>
    <i v-if="separatorClass" class="meta-breadcrumb__separator" :class="separatorClass"></i>
    <span v-else class="meta-breadcrumb__separator" role="presentation">{{separator}}</span>
  </span>
</template>
<script>
export default {
  name: 'MetaBreadcrumbItem',
  props: {
    to: {},
    replace: Boolean
  },
  data () {
    return {
      separator: '',
      separatorClass: ''
    }
  },

  inject: ['liBreadcrumb'],

  mounted () {
    this.separator = this.liBreadcrumb.separator
    this.separatorClass = this.liBreadcrumb.separatorClass
    const link = this.$refs.link
    link.setAttribute('role', 'link')
    link.addEventListener('click', _ => {
      const { to, $router } = this
      if (!to || !$router) return
      this.replace ? $router.replace(to) : $router.push(to)
    })
  }
}
</script>
