<template>
  <div
    class="meta-select"
    :class="[selectSize ? 'meta-select--' + selectSize : '']"
    @click.stop="toggleMenu"
    v-clickoutside="handleClose">
    <div
      class="meta-select__tags"
      v-if="multiple"
      ref="tags"
      :style="{ 'max-width': inputWidth - 32 + 'px', width: '100%' }">
      <span v-if="collapseTags && selected.length">
        <meta-tag
          :closable="!selectDisabled"
          :size="collapseTagSize"
          :hit="selected[0].hitState"
          type="info"
          @close="deleteTag($event, selected[0])"
          disable-transitions>
          <span class="meta-select__tags-text">{{ selected[0].currentLabel }}</span>
        </meta-tag>
        <meta-tag
          v-if="selected.length > 1"
          :closable="false"
          :size="collapseTagSize"
          type="info"
          disable-transitions>
          <span class="meta-select__tags-text">+ {{ selected.length - 1 }}</span>
        </meta-tag>
      </span>
      <transition-group @after-leave="resetInputHeight" v-if="!collapseTags">
        <meta-tag
          v-for="(item,idx) in selected"
          :key="getValueKey(item,idx)"
          :closable="!selectDisabled"
          :size="collapseTagSize"
          :hit="item.hitState"
          :type="!item.formatRight&&allowRepeat?'danger':'info'"
          @close="deleteTag($event, item)"
          disable-transitions>
          <span class="meta-select__tags-text">{{ item.currentLabel }}</span>
        </meta-tag>
      </transition-group>
      <input
        type="text"
        class="meta-select__input"
        :class="[selectSize ? `is-${ selectSize }` : '']"
        :disabled="selectDisabled"
        :autocomplete="autoComplete || autocomplete"
        @focus="handleFocus"
        @blur="softFocus = false"
        @keyup="managePlaceholder"
        @keydown="resetInputState"
        @keydown.down.prevent="navigateOptions('next')"
        @keydown.up.prevent="navigateOptions('prev')"
        @keydown.enter.prevent="selectOption"
        @keydown.esc.stop.prevent="visible = false"
        @keydown.delete="deletePrevTag"
        @keydown.tab="visible = false"
        @compositionstart="handleComposition"
        @compositionupdate="handleComposition"
        @compositionend="handleComposition"
        v-model="query"
        @input="debouncedQueryChange"
        v-if="filterable"
        :style="{ 'flex-grow': '1', width: inputLength / (inputWidth - 32) + '%', 'max-width': inputWidth - 42 + 'px' }"
        ref="input">
    </div>
    <meta-input
      ref="reference"
      v-model="selectedLabel"
      type="text"
      :placeholder="currentPlaceholder"
      :name="name"
      :id="id"
      :autocomplete="autoComplete || autocomplete"
      :size="selectSize"
      :disabled="selectDisabled"
      :readonly="readonly"
      :validate-event="false"
      :class="{ 'is-focus': visible }"
      :tabindex="(multiple && filterable) ? '-1' : null"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup.native="debouncedOnInputChange"
      @keydown.native.down.stop.prevent="navigateOptions('next')"
      @keydown.native.up.stop.prevent="navigateOptions('prev')"
      @keydown.native.enter.prevent="selectOption"
      @keydown.native.esc.stop.prevent="visible = false"
      @keydown.native.tab="visible = false"
      @paste.native="debouncedOnInputChange"
      @mouseenter.native="inputHovering = true"
      @mouseleave.native="inputHovering = false">
      <template slot="prefix" v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </template>
      <template slot="suffix">
        <i v-show="!showClose&&!hiddenSuffix" :class="['meta-select__caret', 'meta-input__icon', 'meta-icon-' + iconClass]"></i>
        <i v-if="showClose&&!hiddenSuffix" class="meta-select__caret meta-input__icon meta-icon-circle-close" @click="handleClearClick"></i>
      </template>
    </meta-input>
    <transition
      name="meta-zoom-in-top"
      @before-enter="handleMenuEnter"
      @after-leave="doDestroy">
      <meta-select-menu
        ref="popper"
        :append-to-body="popperAppendToBody"
        v-show="visible && emptyText !== false">
        <meta-scrollbar
          tag="ul"
          wrap-class="meta-select-dropdown__wrap"
          view-class="meta-select-dropdown__list"
          ref="scrollbar"
          :class="{ 'is-empty': !allowCreate && query && filteredOptionsCount === 0 }"
          v-show="options.length > 0 && !loading"
          :key="pageNum"
          >
          <meta-option
            :value="query"
            created
            v-if="showNewOption">
          </meta-option>
          <slot></slot>
          <meta-button
            class="meta-select-loadMore"
            v-if="pageable && allOptions.length > $slots.default.length"
            @click.native='handleLoadMore($event)'>
            加载更多
          </meta-button>
           <meta-button
            class="meta-select-loadMore"
            v-if="async"
            :loading="asyncLoading"
            @click.native='handleLoadMoreAsync($event)'>
            加载更多
          </meta-button>
        </meta-scrollbar>
        <template v-if="emptyText && (!allowCreate || loading || (allowCreate && options.length === 0 ))">
          <slot name="empty" v-if="$slots.empty"></slot>
          <p class="meta-select-dropdown__empty" v-else>
            {{ emptyText }}
          </p>
        </template>
      </meta-select-menu>
    </transition>
  </div>
</template>

<script type="text/babel">
import index from './select.js'
export default index
</script>
