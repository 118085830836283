// import 'echarts/lib/chart/bar'
// import histogram from './getTypes'
import { histogram } from './bar/main'
import Core from './core'
export default Object.assign({}, Core, {
  name: 'MetaChart',
  data () {
    this.chartHandler = histogram
    return {}
  }
})
