<template>
  <div class="meta-quarter-picker">
    <!-- trigger="manual"
      v-model="visible" -->
    <meta-popover
      ref="popover"
      placement="top"
      title=""
      trigger="manual"
      popper-class="meta-quarter-picker-popover"
      v-model="visible"
      :close-delay="0"
    >
      <Range
        v-if="visibleRange"
        @pick="handlePick"
        @cancel="handleCancel"
        :minDateDefault="minDate"
        :maxDateDefault="maxDate"
        :yearsList="yearsList||[]"
        :quarterList="quarterList||[]"
        :headers="headers"
      />
    <div
      slot="reference"
      class="meta-date-editor meta-range-editor meta-input__inner meta-date-editor--daterange meta-quarter-picker-editor"
    >
      <i class="meta-input__icon meta-range__icon meta-icon-date"></i
      >
      <input
        autocomplete="off"
        :placeholder="placeholders[0]"
        name=""
        class="meta-range-input"
        disable
        @focus="handleFocus"
        readonly="readonly"
        v-model="minDate"
      />
      <span class="meta-range-separator">至</span
      >
      <input
        autocomplete="off"
        :placeholder="placeholders[1]"
        name=""
        class="meta-range-input"
        @focus="handleFocus"
        readonly="readonly"
        v-model="maxDate"
      />
      <i class="meta-icon-close" @click="clear"></i>
    </div>
    </meta-popover>
  </div>
</template>
<script>
import Range from './base/range.vue'
export default {
  props: {
    yearsList: {
      type: Array,
      default: () => []
    },
    quarterList: {
      type: Array,
      default: () => []
    },
    placeholders: {
      type: Array,
      default: () => ['开始时间', '结束时间']
    },
    headers: {
      type: Array,
      default: () => ['开始', '结束']
    },
    value: Array
  },
  name: 'MetaQuarterPicker',
  components: {
    Range: Range
  },
  data () {
    return {
      visible: false,
      visibleRange: false,
      minDate: '',
      maxDate: '',
      hashKey: Date.now()
    }
  },
  watch: {
    value: {
      handler: (val) => {
      },
      deep: true
    },
    visible (val) {
      if (val === false) {
        setTimeout(() => {
          this.visibleRange = val
        }, 200)
        return
      }
      this.visibleRange = val
    }
  },
  created () {
    this.minDate = this.value[0]
    this.maxDate = this.value[1]
    document.addEventListener('click', this.listenClose, false)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.listenClose)
  },
  methods: {
    clear () {
      this.minDate = ''
      this.maxDate = ''
      this.$emit('input', ['', ''])
    },
    handleFocus () {
      this.minDateDefault = this.value[0]
      this.maxDateDefault = this.value[1]
      this.visible = true
    },
    listenClose (e) {
      const flag = document.querySelector('.meta-quarter-picker').contains(e.target)
      if (!flag) {
        this.visible = false
      }
    },
    handleCancel () {
      this.visible = false
    },
    handlePick (val) {
      this.handleCancel()
      const [minDate, maxDate] = val
      this.minDate = minDate
      this.maxDate = maxDate
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>
