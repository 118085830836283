<template>
  <div class="list">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'MetaList',
  componentName: 'MetaList',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    underline: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    href: String,
    icon: String
  },

  methods: {
    handleClick (event) {
      if (!this.disabled) {
        if (!this.href) {
          this.$emit('click', event)
        }
      }
    }
  }
}
</script>
