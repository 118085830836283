<template>
  <div>
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MetaText'
}
</script>
