<template>
  <i :class="'meta-icon-' + name"></i>
</template>

<script>
export default {
  name: 'MetaIcon',

  props: {
    name: String
  }
}
</script>
