<template>
  <div class="meta-time-spinner meta-quarter-spinner">
    <template v-if="!arrowControl">
      <meta-scrollbar
        class="meta-time-spinner__wrapper"
        wrap-style="max-height: inherit;"
        view-class="meta-time-spinner__list"
        noresize
        tag="ul"
        ref="years">
        <li
          @click="handleClick('years', { value: year.value, disabled: year.disabled, index:index })"
          v-for="(year,index) in yearsList || []"
          class="meta-time-spinner__item"
          :key="year.value"
          :class="{ 'active': `${year.value}` === `${years}`, 'disabled': year.disabled }">{{ year.label}}</li>
      </meta-scrollbar>
      <meta-scrollbar
        class="meta-time-spinner__wrapper"
        wrap-style="max-height: inherit;"
        view-class="meta-time-spinner__list"
        noresize
        tag="ul"
        ref="quarters">
        <li
          @click="handleClick('quarters', { value: item.value, disabled: false,index })"
          v-for="(item,index) in quarterList||[]"
          :key="item.value"
          class="meta-time-spinner__item"
          :class="{ 'active': `${item.value}` === `${quarters}`, disabled: item.disabled }">{{item.label}}</li>
      </meta-scrollbar>
    </template>
  </div>
</template>

<script type="text/babel">
import MetaScrollbar from '@@@/scrollbar'
import RepeatClick from '@@/directives/repeat-click'
export default {
  components: { MetaScrollbar },

  directives: {
    repeatClick: RepeatClick
  },

  props: {
    date: String,
    defaultValue: {}, // reserved for future use
    showSeconds: {
      type: Boolean,
      default: true
    },
    arrowControl: Boolean,
    amPmMode: {
      type: String,
      default: '' // 'a': am/pm; 'A': AM/PM
    },
    yearsList: Array,
    quarterList: Array
  },
  watch: {
    date (val) {
      // console.log('======>', val)
      // const arr = val.split('-')
      // console.log('watch==>', val)
      this.initDataSelect(val)
    }
  },

  data () {
    return {
      selectableRange: [],
      currentScrollbar: null,
      years: '',
      quarters: ''
    }
  },

  mounted () {
    this.$nextTick(() => {
      !this.arrowControl && this.bindScrollEvent()
    })
    this.initDataSelect(this.date)
  },

  methods: {
    modifyDateField (type, index) {
      this.$emit('change', `${this.years}-${this.quarters}`)
    },
    initDataSelect (val) {
      try {
        const [years, quarters] = val.split('-')
        const yIndex = this.getIndex(years, this.yearsList)
        const qIndex = this.getIndex(quarters, this.quarterList)
        this.$nextTick(() => {
          this.handleClick('years', { value: years, disabled: false, index: yIndex })
          this.handleClick('quarters', { value: quarters, disabled: false, index: qIndex })
        })
      } catch (error) {
        console.log(error)
      }
    },
    getIndex (val, list = []) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index]
        if (`${element.value}` === `${val}`) {
          return index
        }
      }
      return 0
    },
    handleClick (type, { value, disabled, index }) {
      this[type] = value
      if (!disabled) {
        this.modifyDateField(type, value)
        this.emitSelectRange(type)
        this.adjustSpinner(type, index)
      }
    },

    emitSelectRange (type) {
      this.currentScrollbar = type
    },

    bindScrollEvent () {
      const bindFuntion = (type) => {
        this.$refs[type].wrap.onscroll = (e) => {
          this.handleScroll(type, e)
        }
      }
      bindFuntion('years')
      bindFuntion('quarters')
    },

    handleScroll (type) {
      let index = 0
      index = Math.min(Math.round((this.$refs[type].wrap.scrollTop - (this.scrollBarHeight(type) * 0.5 - 10) / this.typeItemHeight(type) + 3) / this.typeItemHeight(type)))
      try {
        switch (type) {
          case 'years':
            index = index > this.yearsList.length - 1 ? this.yearsList.length - 1 : index
            clearTimeout(this.timer1)
            if (index === this.yearsList.length - 1) {
              this.timer1 = setTimeout(() => {
                clearTimeout(this.timer1)
                this.handleClick('years', { ...this.yearsList[index], index })
              }, 400)
              return
            }
            this.years = this.yearsList[index].value
            break
          case 'quarters':
            clearTimeout(this.timer2)
            index = index > this.quarterList.length - 1 ? this.quarterList.length - 1 : index
            if (index === this.quarterList.length - 1) {
              this.timer2 = setTimeout(() => {
                clearTimeout(this.timer2)
                this.handleClick('quarters', { ...this.quarterList[index], index })
              }, 400)
              return
            }
            this.quarters = this.quarterList[index].value
            break
          default:
            break
        }
        this.modifyDateField(type, index)
      } catch (error) {
        console.log(error)
      }
    },

    adjustSpinners () {
      this.adjustSpinner('years', 1)
      this.adjustSpinner('quarters', 1)
    },

    adjustCurrentSpinner (type) {
      this.adjustSpinner(type, this[type])
    },

    adjustSpinner (type, index) {
      const el = this.$refs[type].wrap
      if (el) {
        el.scrollTop = Math.max(0, index * this.typeItemHeight(type))
      }
    },
    typeItemHeight (type) {
      return this.$refs[type].$el.querySelector('li').offsetHeight
    },
    scrollBarHeight (type) {
      return this.$refs[type].$el.offsetHeight
    }
  }
}
</script>
