<template>
  <transition name="meta-loading-fade" @after-leave="handleAfterLeave">
    <div
      v-show="visible"
      class="meta-loading-mask"
      :style="{ backgroundColor: background || '' }"
      :class="[customClass, { 'is-fullscreen': fullscreen }]">
      <div class="meta-loading-spinner">
        <!-- <svg v-if="!spinner" class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none"/>
        </svg> -->

        <img v-if="isms && !spinner" class="circular" src="https://p.ampmake.com/fed/image/gif/dff0ebfa4944d7ac5fdb8b693f1db73b.gif" alt="">
        <div v-if="!isms && !spinner" class="circular">
          <div class="circular-inner">
            <div class="circular-point"></div>
            <div class="circular-strip strip1"></div>
            <div class="circular-strip strip2"></div>
          </div>
        </div>
        <i v-else :class="spinner"></i>
        <p v-if="text" class="meta-loading-text">{{ text }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data () {
    // 是否为ie或edge浏览器
    var agent = navigator.userAgent
    var isIE = window.ActiveXObject || 'ActiveXObject' in window
    var isEdge = agent.indexOf('Edge') > -1

    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: '',
      isms: isIE || isEdge
    }
  },

  methods: {
    handleAfterLeave () {
      this.$emit('after-leave')
    },
    setText (text) {
      this.text = text
    }
  }
}
</script>
