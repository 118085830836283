<template>
  <footer class="meta-footer" :style="{ height }">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'MetaFooter',

  componentName: 'MetaFooter',

  props: {
    height: {
      type: String,
      default: '60px'
    }
  }
}
</script>
