<template>
  <div v-show="show" id="eagle-eye" class="eagle-eye-ctn" data-html2canvas-ignore>
    <div v-show="loading" id="loading-ctn" class="loading-ctn">
      <i class="meta-icon-loading"></i>
    </div>
    <div v-show="!loading" id="selector-ctn" class="selector-ctn">
      <div id="folder" class="folder">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L3.35355 2.64645ZM9 9.5C9.27614 9.5 9.5 9.27614 9.5 9L9.5 4.5C9.5 4.22386 9.27614 4 9 4C8.72386 4 8.5 4.22386 8.5 4.5L8.5 8.5L4.5 8.5C4.22386 8.5 4 8.72386 4 9C4 9.27614 4.22386 9.5 4.5 9.5L9 9.5ZM2.64645 3.35355L8.64645 9.35355L9.35355 8.64645L3.35355 2.64645L2.64645 3.35355Z" fill="white"/>
        </svg>
      </div>

      <div class="unfolder"></div>

      <div id="scope-ctn" class="scope-ctn">
        <div id="scope-position-marking" class="scope-position-marking"></div>
        <div id="scope-selector" class="scope-selector"></div>
      </div>

      <div id="temp-canvas-ctn"></div>
    </div>
  </div>
</template>

<script>
function debounce (fn, delay) {
  let timer // 维护一个 timer

  return function () {
    const _this = this // 取debounce执行作用域的this
    const args = arguments

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(function () {
      fn.apply(_this, args) // 用apply指向调用debounce的对象，相当于_this.fn(args);
    }, delay)
  }
}

function throttle (fn, delay) {
  let timer

  return function () {
    const _this = this
    const args = arguments
    if (timer) {
      return
    }
    timer = setTimeout(function () {
      fn.apply(_this, args)
      timer = null // 在delay后执行完fn之后清空timer，此时timer为假，throttle触发可以进入计时器
    }, delay)
  }
}

export default {
  name: 'MetaEagleeye',
  props: {
    show: {
      type: Boolean,
      default: true,
      validator (val) {
        return [true, false].indexOf(val) !== -1
      }
    }
  },
  data () {
    return {
      eagleEyeEle: null,
      loadingCtnEle: null,
      selectorCtnEle: null,
      tempCanvasCtnEle: null,
      folderEle: null,
      scopeCtnEle: null,
      scopePositionMarkingEle: null,
      pageWidth: 0,
      pageHeight: 0,
      clientWidth: 0,
      clientHeight: 0,
      scopeCtnEleWidth: 0,
      scopeCtnEleHeight: 0,
      scopePositionMarkingEleWidth: 0,
      scopePositionMarkingEleHeight: 0,
      loading: true
    }
  },
  methods: {
    init (ctn = window, content = document.body) {
      if (!window.html2canvas) {
        console.warn('window.html2canvas不存在，请在页面里通过script标签方式引入https://s.ampmake.com/fed/cdn/html2canvas/0.0.1/html2canvas.min.js')

        return
      }
      this.ctn = ctn
      this.content = content

      this.setEnvParams()
      this.getElements()
      this.setSelector()
      // this.renderThumbnail()
      this.bindFolderEvent()
      this.positeScopeSelector()
      this.bindPageScrollEvent()
      this.bindRepositePageEvent()
      this.bindWindowZoomEvent()
      this.bindCtnResizeEvent()
      this.bindTreeChangeEvent()
      this.resizeEagleeyeCtnEle()
    },
    getElements () {
      this.eagleEyeEle = document.body.querySelector('#eagle-eye')
      this.loadingCtnEle = document.body.querySelector('#loading-ctn')
      this.selectorCtnEle = document.body.querySelector('#selector-ctn')
      this.tempCanvasCtnEle = document.body.querySelector('#temp-canvas-ctn')
      this.folderEle = document.body.querySelector('#folder')
      this.scopeCtnEle = document.body.querySelector('#scope-ctn')
      this.scopePositionMarkingEle = document.body.querySelector('#scope-position-marking')
    },
    setEnvParams () {
      this.loading = true

      this.pageWidth = this.content.scrollWidth
      this.pageHeight = this.content.scrollHeight
      this.clientWidth = this.ctn === window ? this.ctn.innerWidth : this.ctn.offsetWidth
      this.clientHeight = this.ctn === window ? this.ctn.innerHeight : this.ctn.offsetHeight
    },
    setSelector () {
      if ((this.pageWidth / this.pageHeight) > (216 / 128)) {
        this.scopeCtnEleWidth = 216
        this.scopeCtnEleHeight = this.pageHeight * 216 / this.pageWidth

        this.scopeCtnEle.style.width = this.scopeCtnEleWidth + 'px'
        this.scopeCtnEle.style.height = this.scopeCtnEleHeight + 'px'
      } else {
        this.scopeCtnEleHeight = 128
        this.scopeCtnEleWidth = this.pageWidth * 128 / this.pageHeight

        this.scopeCtnEle.style.height = this.scopeCtnEleHeight + 'px'
        this.scopeCtnEle.style.width = this.scopeCtnEleWidth + 'px'
      }

      this.scopePositionMarkingEleWidth = this.clientWidth * this.scopeCtnEleWidth / this.pageWidth
      this.scopePositionMarkingEleHeight = this.clientHeight * this.scopeCtnEleHeight / this.pageHeight

      this.scopePositionMarkingEle.style.height = this.scopePositionMarkingEleHeight + 'px'
      this.scopePositionMarkingEle.style.width = this.scopePositionMarkingEleWidth + 'px'
    },
    renderThumbnail () {
      // console.log(this.pageWidth, this.pageHeight)

      window.html2canvas(this.content, {
        width: this.pageWidth,
        height: this.pageHeight,
        backgroundColor: '#AAA'
      }).then(canvas => {
        this.tempCanvasCtnEle.appendChild(canvas)

        const imgSrc = canvas.toDataURL('image/jpeg')

        const img = new Image()

        img.src = imgSrc

        const existImg = this.selectorCtnEle.querySelector('img')

        if (existImg) {
          this.selectorCtnEle.removeChild(existImg)
        }

        this.selectorCtnEle.appendChild(img)

        this.loading = false
      })
    },
    reRender: debounce(function () {
      // console.log(456, this)

      this.setEnvParams()
      this.setSelector()
      this.renderThumbnail()
    }, 1000),
    treeChange  (records) {
      console.log(records)

      for (let i = 0, len = records.length; i < len; i++) {
        const record = records[i]

        if (
          record.target === this.eagleEyeEle ||
          record.target === this.tempCanvasCtnEle ||
          record.target === this.folderEle ||
          record.target === this.scopeCtnEle ||
          record.target === this.scopePositionMarkingEle ||
          record.target === this.loadingCtnEle ||
          record.target === this.selectorCtnEle
        ) {
          console.log('do nothing....')

          continue
        }

        this.reRender()

        break
      }
    },
    resizeEagleeyeCtnEle () {
      console.log('window resize: ', devicePixelRatio, 1 / devicePixelRatio)

      this.eagleEyeEle.style.opacity = 1
      this.eagleEyeEle.style.zoom = 1 / devicePixelRatio
    },
    bindWindowZoomEvent () {
      window.addEventListener('resize', () => {
        this.reRender()
        this.resizeEagleeyeCtnEle()
      })
    },
    bindCtnResizeEvent () {
      this.ctn.addEventListener('resize', debounce(this.reRender, 500))
    },
    bindTreeChangeEvent () {
      var observer = new MutationObserver(debounce(this.treeChange, 3000))

      observer.observe(this.ctn === window ? document.documentElement : this.ctn, {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true
      })
    },
    bindFolderEvent () {
      this.eagleEyeEle.addEventListener('click', () => {
        if (this.eagleEyeEle.classList.contains('minimal')) {
          this.eagleEyeEle.classList.remove('minimal')

          this.scopeCtnEle.style.display = 'block'
        }
      })

      this.folderEle.addEventListener('click', (e) => {
        e.stopPropagation()

        this.eagleEyeEle.classList.add('minimal')

        this.scopeCtnEle.style.display = 'none'
      })
    },
    positeScopeSelector () {
      const pageScrollTop = this.ctn === window ? this.ctn.scrollY : this.ctn.scrollTop
      const pageScrollLeft = this.ctn === window ? this.ctn.scrollX : this.ctn.scrollLeft

      this.scopePositionMarkingEle.style.top = (this.scopeCtnEleHeight - this.scopePositionMarkingEleHeight) * pageScrollTop / (this.pageHeight - this.clientHeight) + 'px'
      this.scopePositionMarkingEle.style.left = (this.scopeCtnEleWidth - this.scopePositionMarkingEleWidth) * pageScrollLeft / (this.pageWidth - this.clientWidth) + 'px'

      // console.log('positeScopeSelector 1: ', this.scopeCtnEleWidth, this.scopePositionMarkingEleWidth, pageScrollLeft, this.pageWidth, this.clientWidth)
      // console.log('positeScopeSelector 2: ', devicePixelRatio, (this.scopeCtnEleWidth - this.scopePositionMarkingEleWidth) * pageScrollLeft / (this.pageWidth - this.clientWidth), ((this.scopeCtnEleWidth - this.scopePositionMarkingEleWidth) * pageScrollLeft / (this.pageWidth - this.clientWidth)) / devicePixelRatio)
    },
    bindPageScrollEvent () {
      this.ctn.addEventListener('scroll', this.positeScopeSelector)
    },
    bindRepositePageEvent () {
      const doReposite = (e) => {
        // console.log(e)

        e.stopPropagation()

        const scrollTop = (this.pageHeight - this.clientHeight) * (e.offsetY * devicePixelRatio - this.scopePositionMarkingEleHeight / 2) / (this.scopeCtnEleHeight - this.scopePositionMarkingEleHeight)
        const scrollLeft = (this.pageWidth - this.clientWidth) * (e.offsetX * devicePixelRatio - this.scopePositionMarkingEleWidth / 2) / (this.scopeCtnEleWidth - this.scopePositionMarkingEleWidth)

        // console.log('bindRepositePageEvent: ', devicePixelRatio, ((this.pageWidth - this.clientWidth) * (e.offsetX - this.scopePositionMarkingEleWidth / 2) / (this.scopeCtnEleWidth - this.scopePositionMarkingEleWidth)) * devicePixelRatio)

        // console.log(e.offsetX, e.offsetY, scrollLeft, scrollTop, e)

        if (e.offsetX < 1) {
          return
        }

        this.ctn.scrollTo(scrollLeft, scrollTop)
      }

      this.scopeCtnEle.addEventListener('mousedown', e => {
        doReposite(e)

        this.scopeCtnEle.addEventListener('mousemove', doReposite)
      })

      document.body.addEventListener('mouseup', () => {
        console.log('mouseup...')
        this.scopeCtnEle.removeEventListener('mousemove', doReposite)
      })
    }
  }
}
</script>
