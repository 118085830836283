var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meta-badge"},[_vm._t("default"),_c('transition',{attrs:{"name":"meta-zoom-in-center"}},[(_vm.position === 'top')?_c('sup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden && (_vm.content || _vm.content === 0 || _vm.isDot)),expression:"!hidden && (content || content === 0 || isDot)"}],staticClass:"meta-badge__content",class:[
        'meta-badge__content--' + _vm.type,
        {
          'is-fixed': _vm.$slots.default,
          'is-dot': _vm.isDot
        }
      ],domProps:{"textContent":_vm._s(_vm.content)}}):_vm._e(),(_vm.position === 'middle')?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden && (_vm.content || _vm.content === 0 || _vm.isDot)),expression:"!hidden && (content || content === 0 || isDot)"}],staticClass:"meta-badge__content",class:[
        'meta-badge__content--' + _vm.type,
        {
          'is-fixed': _vm.$slots.default,
          'is-dot': _vm.isDot
        }
      ],domProps:{"textContent":_vm._s(_vm.content)}}):_vm._e(),(_vm.position === 'bottom')?_c('sub',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden && (_vm.content || _vm.content === 0 || _vm.isDot)),expression:"!hidden && (content || content === 0 || isDot)"}],staticClass:"meta-badge__content",class:[
        'meta-badge__content--' + _vm.type,
        {
          'is-fixed': _vm.$slots.default,
          'is-dot': _vm.isDot
        }
      ],domProps:{"textContent":_vm._s(_vm.content)}}):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }