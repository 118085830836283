import MetaPopover from './src/main'
import directive from './src/directive'
import Vue from 'vue'

Vue.directive('popover', directive)

/* istanbul ignore next */
MetaPopover.install = function (Vue) {
  Vue.directive('popover', directive)
  Vue.component(MetaPopover.name, MetaPopover)
}
MetaPopover.directive = directive

export default MetaPopover
