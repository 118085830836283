<template>
  <div class="meta-badge">
    <slot></slot>
    <transition name="meta-zoom-in-center">
      <sup
        v-if="position === 'top'"
        v-show="!hidden && (content || content === 0 || isDot)"
        v-text="content"
        class="meta-badge__content"
        :class="[
          'meta-badge__content--' + type,
          {
            'is-fixed': $slots.default,
            'is-dot': isDot
          }
        ]">
      </sup>
      <span
        v-if="position === 'middle'"
        v-show="!hidden && (content || content === 0 || isDot)"
        v-text="content"
        class="meta-badge__content"
        :class="[
          'meta-badge__content--' + type,
          {
            'is-fixed': $slots.default,
            'is-dot': isDot
          }
        ]">
      </span>
      <sub
        v-if="position === 'bottom'"
        v-show="!hidden && (content || content === 0 || isDot)"
        v-text="content"
        class="meta-badge__content"
        :class="[
          'meta-badge__content--' + type,
          {
            'is-fixed': $slots.default,
            'is-dot': isDot
          }
        ]">
      </sub>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MetaBadge',

  props: {
    value: [String, Number],
    max: Number,
    isDot: Boolean,
    hidden: Boolean,
    type: {
      type: String,
      validator (val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1
      }
    },
    position: {
      type: String,
      validator (val) {
        return ['top', 'middle', 'bottom'].indexOf(val) > -1
      },
      default: 'top'
    }
  },

  computed: {
    content () {
      if (this.isDot) return

      const value = this.value
      const max = this.max

      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? `${max}+` : value
      }

      return value
    }
  }
}
</script>
