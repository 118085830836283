<script>
export default {
  name: 'MetaTag',
  props: {
    text: String,
    closable: Boolean,
    type: String,
    hit: Boolean,
    disableTransitions: Boolean,
    color: String,
    size: String,
    effect: {
      type: String,
      default: 'light',
      validator (val) {
        return ['dark', 'light', 'plain'].indexOf(val) !== -1
      }
    }
  },
  methods: {
    handleClose (event) {
      event.stopPropagation()
      this.$emit('close', event)
    },
    handleClick (event) {
      this.$emit('click', event)
    }
  },
  computed: {
    tagSize () {
      return this.size || (this.$METAUI || {}).size
    }
  },
  render (h) {
    const { type, tagSize, hit, effect } = this
    const classes = [
      'meta-tag',
      type ? `meta-tag--${type}` : '',
      tagSize ? `meta-tag--${tagSize}` : '',
      effect ? `meta-tag--${effect}` : '',
      hit && 'is-hit'
    ]
    const tagEl = (
      <span
        class={ classes }
        style={{ backgroundColor: this.color }}
        on-click={ this.handleClick }>
        { this.$slots.default }
        {
          this.closable && <i class="meta-tag__close meta-icon-close" on-click={ this.handleClose }></i>
        }
      </span>
    )

    return this.disableTransitions ? tagEl : <transition name="meta-zoom-in-center">{ tagEl }</transition>
  }
}
</script>
