<template>
  <transition-group
    tag="ul"
    :class="[
      'meta-upload-list',
      'meta-upload-list--' + listType,
      { 'is-disabled': disabled }
    ]"
    name="meta-list"
  >
    <li
      v-for="file in files"
      :class="['meta-upload-list__item', 'is-' + file.status, focusing ? 'focusing' : '']"
      :key="file.uid"
      tabindex="0"
      @keydown.delete="!disabled && $emit('remove', file)"
      @focus="focusing = true"
      @blur="focusing = false"
      @click="focusing = false"
    >
      <slot :file="file">
        <img
          class="meta-upload-list__item-thumbnail"
          v-if="file.status !== 'uploading' && ['picture-card', 'picture'].indexOf(listType) > -1"
          :src="file.url" alt=""
        >
        <a class="meta-upload-list__item-name" @click="handleClick(file)">
          <i class="meta-icon-document"></i>{{file.name}}
        </a>
        <label class="meta-upload-list__item-status-label">
          <i :class="{
            'meta-icon-upload-success': true,
            'meta-icon-circle-check': listType === 'text',
            'meta-icon-check': ['picture-card', 'picture'].indexOf(listType) > -1
          }"></i>
        </label>
        <i class="meta-icon-close" v-if="!disabled" @click="$emit('remove', file)"></i>
        <i class="meta-icon-close-tip" v-if="!disabled">{{ t('el.upload.deleteTip') }}</i> <!--因为close按钮只在dom:focus的时候 display, dom blur后就不存在了，所以键盘导航时永远无法 focus到 close按钮上-->
        <meta-progress
          v-if="file.status === 'uploading'"
          :type="listType === 'picture-card' ? 'circle' : 'line'"
          :stroke-width="listType === 'picture-card' ? 6 : 2"
          :percentage="parsePercentage(file.percentage)">
        </meta-progress>
        <span class="meta-upload-list__item-actions" v-if="listType === 'picture-card'">
          <span
            class="meta-upload-list__item-preview"
            v-if="handlePreview && listType === 'picture-card'"
            @click="handlePreview(file)"
          >
            <i class="meta-icon-zoom-in"></i>
          </span>
          <span
            v-if="!disabled"
            class="meta-upload-list__item-delete"
            @click="$emit('remove', file)"
          >
            <i class="meta-icon-delete"></i>
          </span>
        </span>
      </slot>
    </li>
  </transition-group>
</template>
<script>
import Locale from '@@/mixins/locale'
import MetaProgress from '@@@/progress'

export default {

  name: 'MetaUploadList',

  mixins: [Locale],

  data () {
    return {
      focusing: false
    }
  },
  components: { MetaProgress },

  props: {
    files: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    handlePreview: Function,
    listType: String
  },
  methods: {
    parsePercentage (val) {
      return parseInt(val, 10)
    },
    handleClick (file) {
      this.handlePreview && this.handlePreview(file)
    }
  }
}
</script>
