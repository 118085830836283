<template>
  <meta-popover
    v-bind="$attrs"
    v-model="visible"
    trigger="click"
  >
  <div class="meta-popconfirm">
    <p class="meta-popconfirm__main">
    <i
      v-if="!hideIcon"
      :class="icon"
      class="meta-popconfirm__icon"
      :style="{color: iconColor}"
    ></i>
      {{title}}
    </p>
    <div class="meta-popconfirm__action">
      <meta-button
        :type="cancelButtonType"
        @click="cancel"
      >
        {{cancelButtonText}}
      </meta-button>
      <meta-button
        :type="confirmButtonType"
        @click="confirm"
      >
        {{confirmButtonText}}
      </meta-button>
    </div>
  </div>
  <slot name="reference" slot="reference"></slot>
</meta-popover>
</template>

<script>
import MetaPopover from '../../popover'
import MetaButton from '../../button'
import { t } from '@@/locale'

export default {
  name: 'MetaPopconfirm',
  props: {
    title: {
      type: String
    },
    confirmButtonText: {
      type: String,
      default: t('el.popconfirm.confirmButtonText')
    },
    cancelButtonText: {
      type: String,
      default: t('el.popconfirm.cancelButtonText')
    },
    confirmButtonType: {
      type: String,
      default: 'primary'
    },
    cancelButtonType: {
      type: String,
      default: 'default'
    },
    icon: {
      type: String,
      default: 'meta-icon-question'
    },
    iconColor: {
      type: String,
      default: '#f90'
    },
    hideIcon: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MetaPopover,
    MetaButton
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    confirm () {
      this.visible = false
      this.$emit('onConfirm')
    },
    cancel () {
      this.visible = false
      this.$emit('onCancel')
    }
  }
}
</script>
