/* eslint-disable */
// This file is auto gererated by build/build-entry.js
import Alert from './alert'
import Aside from './aside'
import Autocomplete from './autocomplete'
import Avatar from './avatar'
import Backtop from './backtop'
import Badge from './badge'
import Breadcrumb from './breadcrumb'
import BreadcrumbItem from './breadcrumb-item'
import Button from './button'
import ButtonGroup from './button-group'
import Calendar from './calendar'
import Card from './card'
import Carousel from './carousel'
import CarouselItem from './carousel-item'
import Cascader from './cascader'
import CascaderPanel from './cascader-panel'
import CascaderPanelRb from './cascader-panel-rb'
import CascaderRb from './cascaderRb'
import Chart from './chart'
import Checkbox from './checkbox'
import CheckboxButton from './checkbox-button'
import CheckboxGroup from './checkbox-group'
import Col from './col'
import Collapse from './collapse'
import CollapseItem from './collapse-item'
import CollapseTransition from './collapse-transition'
import ColorPicker from './color-picker'
import Container from './container'
import DatePicker from './date-picker'
import Dialog from './dialog'
import Divider from './divider'
import Drawer from './drawer'
import Dropdown from './dropdown'
import DropdownItem from './dropdown-item'
import DropdownMenu from './dropdown-menu'
import Eagleeye from './eagleeye'
import FeCol from './fe-col'
import Footer from './footer'
import Form from './form'
import FormItem from './form-item'
import Header from './header'
import Icon from './icon'
import Image from './image'
import InfiniteScroll from './infinite-scroll'
import Input from './input'
import InputNumber from './input-number'
import Link from './link'
import List from './list'
import ListItem from './list-item'
import Loading from './loading'
import Main from './main'
import Menu from './menu'
import MenuItem from './menu-item'
import MenuItemGroup from './menu-item-group'
import Message from './message'
import MessageBox from './message-box'
import Notification from './notification'
import Option from './option'
import OptionGroup from './option-group'
import Pagination from './pagination'
import Popconfirm from './popconfirm'
import Popover from './popover'
import Progress from './progress'
import QuarterPicker from './quarter-picker'
import Radio from './radio'
import RadioButton from './radio-button'
import RadioGroup from './radio-group'
import Rate from './rate'
import Row from './row'
import Scrollbar from './scrollbar'
import Select from './select'
import Slider from './slider'
import Spinner from './spinner'
import Step from './step'
import Steps from './steps'
import Submenu from './submenu'
import Switch from './switch'
import TabPane from './tab-pane'
import Table from './table'
import TableColumn from './table-column'
import Tabs from './tabs'
import Tag from './tag'
import Text from './text'
import TimePicker from './time-picker'
import TimeSelect from './time-select'
import Timeline from './timeline'
import TimelineItem from './timeline-item'
import Tooltip from './tooltip'
import Transfer from './transfer'
import Tree from './tree'
import Upload from './upload'
import popupManager from '../src/utils/popup/popup-manager'

const version = '0.54.0'
const components = [
  Alert,
  Aside,
  Autocomplete,
  Avatar,
  Backtop,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Calendar,
  Card,
  Carousel,
  CarouselItem,
  Cascader,
  CascaderPanel,
  CascaderPanelRb,
  CascaderRb,
  Chart,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  CollapseTransition,
  ColorPicker,
  Container,
  DatePicker,
  Dialog,
  Divider,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Eagleeye,
  FeCol,
  Footer,
  Form,
  FormItem,
  Header,
  Icon,
  Image,
  Input,
  InputNumber,
  Link,
  List,
  ListItem,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Option,
  OptionGroup,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  QuarterPicker,
  Radio,
  RadioButton,
  RadioGroup,
  Rate,
  Row,
  Scrollbar,
  Select,
  Slider,
  Spinner,
  Step,
  Steps,
  Submenu,
  Switch,
  TabPane,
  Table,
  TableColumn,
  Tabs,
  Tag,
  Text,
  TimePicker,
  TimeSelect,
  Timeline,
  TimelineItem,
  Tooltip,
  Transfer,
  Tree,
  Upload,
  popupManager
]
const install = Vue => {
  components.forEach(Component => {
    Vue.use(Component)
  })
  Vue.use(InfiniteScroll);
  Vue.use(Loading.directive);
  Vue.prototype.$loading = Loading.service;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$message = Message;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$notify = Notification;
  Vue.prototype.$popupManager = popupManager;
};
/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}
export {
  install,
  version,
  Alert,
  Aside,
  Autocomplete,
  Avatar,
  Backtop,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Calendar,
  Card,
  Carousel,
  CarouselItem,
  Cascader,
  CascaderPanel,
  CascaderPanelRb,
  CascaderRb,
  Chart,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Col,
  Collapse,
  CollapseItem,
  CollapseTransition,
  ColorPicker,
  Container,
  DatePicker,
  Dialog,
  Divider,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Eagleeye,
  FeCol,
  Footer,
  Form,
  FormItem,
  Header,
  Icon,
  Image,
  InfiniteScroll,
  Input,
  InputNumber,
  Link,
  List,
  ListItem,
  Loading,
  Main,
  Menu,
  MenuItem,
  MenuItemGroup,
  Message,
  MessageBox,
  Notification,
  Option,
  OptionGroup,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  QuarterPicker,
  Radio,
  RadioButton,
  RadioGroup,
  Rate,
  Row,
  Scrollbar,
  Select,
  Slider,
  Spinner,
  Step,
  Steps,
  Submenu,
  Switch,
  TabPane,
  Table,
  TableColumn,
  Tabs,
  Tag,
  Text,
  TimePicker,
  TimeSelect,
  Timeline,
  TimelineItem,
  Tooltip,
  Transfer,
  Tree,
  Upload,
  popupManager
}
export default {
  install,
  version
}
