<template>
    <!-- name="meta-zoom-in-top" -->
    <div
      v-show="visible"
      class="meta-time-range-picker meta-picker-panel meta-quarter-panel"
      :class="popperClass"
      style="margin:0px"
      :width="'262px'"
      >
      <div class="meta-time-range-picker__content">
        <div class="meta-time-range-picker__cell">
          <!-- <div class="meta-time-range-picker__header"> {{ headers[0] }}</div> -->
          <div
            :class="{ 'has-seconds': showSeconds, 'is-arrow': arrowControl }"
            class="meta-time-range-picker__body meta-time-panel__content meta-quarter-panel__content">
            <time-spinner
              ref="minSpinner"
              :show-seconds="showSeconds"
              :yearsList="yearsList"
              :quarterList="quarterList"
              @change="handleMinChange"
              :arrow-control="arrowControl"
              :date="minDate">
            </time-spinner>
          </div>
        </div>
        <div class="meta-time-range-picker__cell">
          <!-- <div class="meta-time-range-picker__header">{{ headers[1] }}</div> -->
          <div
            :class="{ 'has-seconds': showSeconds, 'is-arrow': arrowControl }"
            class="meta-time-range-picker__body meta-time-panel__content meta-quarter-panel__content">
            <time-spinner
              ref="maxSpinner"
              :show-seconds="showSeconds"
              :yearsList="yearsList"
              :quarterList="quarterList"
              @change="handleMaxChange"
              :arrow-control="arrowControl"
              :date="maxDate">
            </time-spinner>
          </div>
        </div>
      </div>
      <div class="meta-time-panel__footer">
        <button
          type="button"
          class="meta-time-panel__btn cancel"
          @click="handleCancel()">{{ t('el.datepicker.cancel') }}</button>
        <button
          type="button"
          class="meta-time-panel__btn confirm"
          @click="handleConfirm()"
          :disabled="btnDisabled">{{ t('el.datepicker.confirm') }}</button>
      </div>
    </div>
</template>

<script type="text/babel">
import Locale from '@@/mixins/locale'
import TimeSpinner from './spinner'
export default {
  props: {
    dodestroy: Function,
    pick: Function,
    cancel: Function,
    minDateDefault: String,
    maxDateDefault: String,
    yearsList: Array,
    quarterList: Array,
    headers: Array
  },
  mixins: [Locale],
  created () {
    this.minDate = this.minDateDefault
    this.maxDate = this.maxDateDefault
  },
  components: { TimeSpinner },

  computed: {
    showSeconds () {
      return false
    },

    offset () {
      return this.showSeconds ? 11 : 8
    },

    spinner () {
      return this.selectionRange[0] < this.offset ? this.$refs.minSpinner : this.$refs.maxSpinner
    },

    btnDisabled () {
      return false
      // return this.minDate.getTime() > this.maxDate.getTime()
    }
  },

  data () {
    return {
      popperClass: '',
      minDate: '',
      maxDate: '',
      value: [],
      defaultValue: null,
      format: 'HH:mm:ss',
      visible: true,
      selectionRange: [0, 2],
      arrowControl: false
    }
  },

  watch: {
    visible (val) {
      if (val) {
        this.oldValue = this.value
        this.$nextTick(() => this.$refs.minSpinner.emitSelectRange('years'))
      }
    },
    value: {
      handler: function (params) {
      },
      deep: true
    }
  },

  methods: {
    handleClear () {
      this.$emit('pick', null)
    },

    handleCancel () {
      this.$emit('cancel')
    },

    handleMinChange (date) {
      this.minDate = date
      this.handleChange()
    },

    handleMaxChange (date) {
      this.maxDate = date
      this.handleChange()
    },

    handleChange () {
      // if (this.isValidValue([this.minDate, this.maxDate])) {
      // this.$refs.minSpinner.selectableRange = selectableRange
      // this.$refs.maxSpinner.selectableRange = [[this.minDate, maxTimeOfDay(this.maxDate)]]
      // this.$emit('pick', [this.minDate, this.maxDate])
      // }
    },

    handleConfirm (visible = false) {
      if (this.minDate > this.maxDate) {
        this.$emit('pick', [this.minDate, this.maxDate].reverse())
        this.$emit('change', [this.minDate, this.maxDate].reverse())
        return
      }
      this.$emit('pick', [this.minDate, this.maxDate])
      this.$emit('change', [this.minDate, this.maxDate])
    },

    adjustSpinners () {
      this.$refs.minSpinner.adjustSpinners(this.minDate)
      this.$refs.maxSpinner.adjustSpinners(this.maxDate)
    }
  }
}
</script>
