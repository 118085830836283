<template>
  <div class="list-item" @click="handleClick">
    <slot v-if="$slots.default"></slot>
    <div   v-if="!$slots.default && !!icon" class="list-icon">
      <i :class="icon"></i>
    </div>
    <div v-if="!$slots.default" class="list-main">{{text}}</div>
  </div>
</template>

<script>

export default {
  name: 'MetaListItem',
  componentName: 'MetaListItem',
  props: {
    icon: String,
    text: String
  },

  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>
